import "@yumgmbh/gatsby-theme-yum-components/src/scss/style.scss"

export const onRouteUpdate = ({ location }) => {
  if (!Array.isArray(window._hsq)) {
    return null
  }

  const trackPageView = () => {
    const path = location
      ? `${location.pathname}${location.search}${location.hash}`
      : undefined
    window._hsq.push(["setPath", path])
    window._hsq.push(["trackPageView"])
  }

  // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
  if (typeof window.requestAnimationFrame === "function") {
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(trackPageView)
    })
  } else {
    // simulate 2 requestAnimationFrame calls
    setTimeout(trackPageView, 32)
  }

  return null
}
