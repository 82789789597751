module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@yumgmbh/gatsby-theme-yum-components/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@yumgmbh/gatsby-plugin-sb-nav/gatsby-browser.js'),
      options: {"plugins":[],"storyblokToken":"ZgwWb9tbVkL4f1ZBXLSQyQtt","version":"published"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/mm-favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"be891cc170c0df05fa363ed02cb0cda1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://matomo.morgenundmorgen.com","siteUrl":"https://morgenundmorgen.com","dev":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
